<template>
  <simple-card title="Moving plan" @click:back="handleBack">
    <base-list :items="items" />
    <v-list>
      <v-list-item>
        <v-btn outlined block @click="handleSave">Save</v-btn>
      </v-list-item>
    </v-list>
  </simple-card>
</template>

<script>
import SimpleCard from '@/components/simple-card';
import { usePlan } from '../../compositions/plan';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  watch
} from 'vue-demi';
import { genDefaultItem } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';
import { usePopup } from '@/compositions/popup';
import { onBeforeRouteLeave } from '@/compositions/router';
import { throttle } from 'throttle-debounce';
import { usePlans } from '@/modules/plans/compositions/plans';
import { objectPropertyService } from '@/modules/common/api';
import { formatCoordinates } from '@/utils';

export default {
  name: 'PlanMoving',
  components: {
    SimpleCard
  },
  props: {
    planId: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const { planId } = toRefs(props);
    const state = ref({
      width: '',
      height: '',
      rotation: '',
      centerLat: '',
      centerLon: '',
      name: '',
      address: ''
    });
    const edited = ref(false);
    const { item: entity, load } = usePlan(planId.value);
    const { list } = usePlans();
    const {
      plans: {
        setMapToPlanCenter,
        selectPlans,
        enableEditPlan,
        disableEditPlan,
        editPlansState,
        setPlans,
        showedPlanIds
      }
    } = useMap();
    const popup = usePopup();

    onBeforeRouteLeave((to, from, next) => {
      if (edited.value) {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Save new geometry of the plan?',
            onCancel: () => {
              setPlans(
                list.value.map(i => ({
                  ...i,
                  width: i.positionWidth,
                  height: i.positionHeight,
                  rotation: i.positionRotation,
                  center: [i.positionCenter.lon, i.positionCenter.lat]
                }))
              );
              next();
            },
            onConfirm: async () => {
              await handleSave();
              next();
            }
          }
        });
      } else {
        next();
      }
    });

    const handleBack = () => {
      root.$router.push({
        name: 'plan_card',
        params: root.$route.params
      });
    };

    const handleSave = async () => {
      // disableEditPlan(planId.value);
      await objectPropertyService.updateValues([
        {
          id: entity.value.positionCenter.id,
          value: {
            lat: state.value.centerLat,
            lon: state.value.centerLon
          }
        },
        {
          id: entity.value.positionWidth.id,
          value: state.value.width
        },
        {
          id: entity.value.positionHeight.id,
          value: state.value.height
        },
        {
          id: entity.value.positionRotation.id,
          value: state.value.rotation
        }
      ]);
      setMapToPlanCenter(planId.value);
    };

    const items = computed(() => [
      genDefaultItem({
        subTitle: `Name: ${state.value.name}`,
        icon: '$plan',
        invert: true
      }),
      genDefaultItem({
        subTitle: `Center: ${formatCoordinates({
          lon: state.value.centerLon,
          lat: state.value.centerLat
        })}`,
        icon: '$marker_set',
        invert: true
      }),
      genDefaultItem({
        subTitle: `Rotation: ${state.value.rotation}°`,
        icon: '$rotation',
        invert: true
      }),
      genDefaultItem({
        subTitle: `Size: ${state.value.height} x ${state.value.width} m`,
        icon: '$size',
        invert: true
      })
    ]);

    onMounted(async () => {
      await load();
      selectPlans([planId.value]);
      showedPlanIds.value = [
        ...new Set([...showedPlanIds.value, planId.value])
      ];
      setMapToPlanCenter(planId.value);
      enableEditPlan(planId.value);
    });

    onBeforeUnmount(() => {
      disableEditPlan(planId.value);
      selectPlans([]);
    });

    const throttleUpdate = throttle(500, value => {
      state.value = {
        ...state.value,
        ...value
      };
    });

    watch(
      () => entity.value,
      value => {
        state.value = {
          name: value.name,
          address: value.positionAddress?.value,
          width: value.positionWidth?.value,
          height: value.positionHeight?.value,
          centerLon: value.positionCenter?.value?.lon,
          centerLat: value.positionCenter?.value?.lat,
          rotation: value.positionRotation?.value
        };
        edited.value = false;
      }
    );

    watch(
      () => editPlansState.value,
      value => {
        throttleUpdate(value[planId.value]);
        edited.value = true;
      }
    );

    return {
      handleBack,
      items,
      handleSave,
      entity,
      editPlansState,
      state
    };
  }
};
</script>

<style></style>
