<template>
  <list-card
    ref="refList"
    :items="items"
    :list-actions="listActions"
    :group-actions="groupActions"
    :filters="filters"
    :sorting="sorting"
    :current-sort-by="currentSortBy"
    title="Connections"
    @click:sort="handleSort"
    @click:filter="handleFilter"
    @selectedItemIds="handleSelectedItemIds"
  >
    <template #item="{select, ...props}">
      <connection-list-item
        v-bind="props"
        :actions="getItemActions(props.item)"
        :to="getItemRoute(props.item)"
        @select="select"
      />
    </template>
  </list-card>
</template>

<script>
import ListCard from '@/components/list-card';
import ConnectionListItem from './ConnectionListItem';
import { useObjectListActions } from './listActions';
import { useObjectFilterMenu } from './filterMenu';

import { computed, ref } from '@vue/composition-api';

import { useSortBy } from '@/compositions/sortBy';
import { useFilterBy } from '@/compositions/filterBy';
import { useConnectionMenu } from '@/modules/connections/compositions/connectionMenu';
import { useConnections } from '@/modules/connections/compositions/connections';
import sortingMenu from './sortingMenu';
import { usePopup } from '@/compositions/popup';

export default {
  name: 'Connections',
  components: {
    ListCard,
    ConnectionListItem
  },

  setup(props, { root }) {
    const refList = ref(null);
    const { list } = useConnections();

    const clearSelection = () => {
      refList.value && refList.value.clearSelection();
    };

    const getItemRoute = item => ({
      name: 'connection_card',
      params: {
        connectionId: item.id
      },
      query: root.$route.query
    });

    const currentSortBy = ref(sortingMenu[0].id);

    const selectedIds = ref([]);

    const getItemActions = entity => {
      return useConnectionMenu({ entity });
    };

    const keyword = computed(() => {
      return root.$route?.query?.keyword;
    });

    const searchedList = computed(() => {
      return keyword.value
        ? list.value.filter(i =>
            i.name.toLowerCase().includes(keyword.value.toLowerCase())
          )
        : list.value;
    });

    const {
      menuItems: filters,
      handleFilter,
      filterFunctions
    } = useObjectFilterMenu();

    const { filteredItems } = useFilterBy(searchedList, filterFunctions);

    const { sortType, sortKey, sortedItems } = useSortBy(
      filteredItems,
      sortingMenu[0].sortKey,
      sortingMenu[0].sortType
    );

    const handleSort = e => {
      currentSortBy.value = e.id;
      sortType.value = e.sortType;
      sortKey.value = e.sortKey;
    };

    const { listActions } = useObjectListActions();

    const handleSelectedItemIds = ids => {
      selectedIds.value = ids;
    };

    const groupActions = [
      // genMenuItemDelete(() => {
      //   popup.openConfirm({
      //     component: () => import('@/components/popup/PopupConfirmAction.vue'),
      //     props: {
      //       title: 'Delete these connections completely?',
      //       onConfirm: () => {
      //         linkedObjectService.massiveDelete(
      //           selectedIds.value.map(item => ({ id: item }))
      //         );
      //         clearSelection();
      //       }
      //     }
      //   });
      // })
    ];

    return {
      getItemRoute,
      items: sortedItems,
      getItemActions,
      listActions,
      groupActions,
      filters,
      handleFilter,
      sorting: sortingMenu,
      currentSortBy,
      handleSort,
      handleSelectedItemIds,
      list,
      refList
    };
  }
};
</script>

<style></style>
