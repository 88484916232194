<template>
  <simple-card title="Moving geozone" @click:back="handleBack">
    <base-list :items="items" />
    <v-list>
      <v-list-item>
        <v-btn outlined block :loading="loading" @click="handleSave">
          Save
        </v-btn>
      </v-list-item>
    </v-list>
  </simple-card>
</template>

<script>
import SimpleCard from '@/components/simple-card';
import { useGeozone } from '../../compositions/geozone';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  watch
} from 'vue-demi';
import { genDefaultItem } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';
import { usePopup } from '@/compositions/popup';
import { onBeforeRouteLeave } from '@/compositions/router';
import { throttle } from 'throttle-debounce';
import { useGeozones } from '@/modules/geozones/compositions/geozones';
import { objectPropertyService } from '@/modules/common/api';
import { useGeotags } from '@/modules/geotags/compositions/geotags';

export default {
  name: 'GeozoneMoving',
  components: {
    SimpleCard
  },
  props: {
    geozoneId: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const { geozoneId } = toRefs(props);
    const state = ref({
      name: '',
      altitude: '',
      address: '',
      points: []
    });
    const edited = ref(false);
    const loading = ref(false);
    const { item: entity, load } = useGeozone(geozoneId.value);
    const { list } = useGeozones();
    const {
      geozones: {
        setMapToGeozoneCenter,
        selectedGeozoneIds,
        enableEditGeozone,
        disableEditGeozone,
        editGeozonesState,
        setGeozones
      },
      markers: { clearMarkers }
    } = useMap();
    const popup = usePopup();
    const { getGeotagById } = useGeotags();

    onBeforeRouteLeave((to, from, next) => {
      if (edited.value) {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Save new geometry of the geozone?',
            onCancel: () => {
              setGeozones(list.value);
              next();
            },
            onConfirm: async () => {
              await handleSave();
              next();
            }
          }
        });
      } else {
        next();
      }
    });

    const handleBack = () => {
      root.$router.push({
        name: 'geozone_card',
        params: root.$route.params
      });
    };

    const handleSave = async () => {
      try {
        loading.value = true;
        await objectPropertyService.updateValues([
          {
            id: entity.value.positionPoints.id,
            value: state.value.points
          }
        ]);
        setMapToGeozoneCenter(geozoneId.value);
      } finally {
        loading.value = false;
      }
    };

    const items = computed(() => [
      genDefaultItem({
        subTitle: `Name: ${state.value.name}`,
        icon: '$geozone',
        invert: true
      }),
      genDefaultItem({
        subTitle: `Altitude: ${state.value.altitude}`,
        icon: '$marker_set',
        invert: true
      }),
      genDefaultItem({
        subTitle: `Geotag: ${getGeotagById(
          entity.value?.positionGeotagId?.value
        )?.name || 'n/a'}`,
        icon: '$geotag',
        invert: true
      })
    ]);

    onMounted(() => {
      load();
    });

    onBeforeUnmount(() => {
      disableEditGeozone(geozoneId.value);
    });

    const throttleUpdate = throttle(500, value => {
      state.value = {
        ...state.value,
        ...value
      };
    });

    watch(
      () => entity.value,
      value => {
        selectedGeozoneIds.value = [geozoneId.value];
        clearMarkers();
        setGeozones([entity.value]);
        setMapToGeozoneCenter(geozoneId.value);
        enableEditGeozone(geozoneId.value);
        state.value = {
          name: value.name,
          address: value.positionAddress?.value,
          altitude: value.positionAltitude?.value,
          points: value.points
        };
        edited.value = false;
      }
    );

    watch(
      () => editGeozonesState.value,
      value => {
        throttleUpdate(value[geozoneId.value]);
        edited.value = true;
      }
    );

    return {
      handleBack,
      items,
      handleSave,
      entity,
      editGeozonesState,
      state,
      loading
    };
  }
};
</script>

<style></style>
