<template>
  <list-card
    ref="refList"
    :items="items"
    :list-actions="listActions"
    :group-actions="groupActions"
    :is-filter-active="isFilterActive"
    :sorting="sorting"
    :current-sort-by="currentSortBy"
    title="Geotags"
    entity-type="geotag"
    @click:sort="handleSort"
    @click:filter-icon="handleFilterOpen"
    @selectedItemIds="handleSelectedItemIds"
    @active="handleActive"
  >
    <template #item="{select, ...props}">
      <geotag-list-item
        v-bind="props"
        :actions="getItemActions(props.item)"
        :to="getItemRoute(props.item)"
        @select="select"
      />
    </template>
  </list-card>
</template>

<script>
import ListCard from '@/components/list-card';
import GeotagListItem from './GeotagsListItem';
import { useObjectListActions } from './listActions';

import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from '@vue/composition-api';

import { useSortBy } from '@/compositions/sortBy';
import { useGeotagMenu } from '@/modules/geotags/compositions/geotagMenu';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import sortingMenu from './sortingMenu';
import { useMap } from '@/compositions/map';
import { genMenuItemDelete } from '@/utils/list-generators';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { usePopup } from '@/compositions/popup';
import { useFilter } from '@/compositions/filter';
import {
  GEOTAG_LIST_FILTER,
  useGeotagFilters
} from '@/modules/geotags/compositions/geotag-filters';

export default {
  name: 'Geotags',
  components: {
    ListCard,
    GeotagListItem
  },

  setup(props, { root }) {
    const refList = ref(null);
    const popup = usePopup();
    const { list } = useGeotags();
    const {
      geotags,
      markers: { clearMarkers }
    } = useMap();

    const clearSelection = () => {
      refList.value && refList.value.clearSelection();
    };

    const getItemRoute = item => ({
      name: 'geotag_card',
      params: {
        geotagId: item.id
      },
      query: root.$route.query
    });

    const currentSortBy = ref(sortingMenu[0].id);

    const selectedIds = ref([]);

    const getItemActions = entity => {
      return useGeotagMenu({ entity });
    };

    const keyword = computed(() => {
      return root.$route?.query?.keyword;
    });

    const searchedList = computed(() => {
      return keyword.value
        ? list.value.filter(i =>
            i.name.toLowerCase().includes(keyword.value.toLowerCase())
          )
        : list.value;
    });

    const filters = useGeotagFilters();

    const {
      filteredItems,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen
    } = useFilter(searchedList, filters, GEOTAG_LIST_FILTER);

    const { sortType, sortKey, sortedItems } = useSortBy(
      filteredItems,
      sortingMenu[0].sortKey,
      sortingMenu[0].sortType
    );

    const handleSort = e => {
      currentSortBy.value = e.id;
      sortType.value = e.sortType;
      sortKey.value = e.sortKey;
    };

    const { listActions } = useObjectListActions();

    const handleSelectedItemIds = ids => {
      selectedIds.value = ids;
      geotags.selectGeotags(ids);
    };

    const handleActive = ({ id, active }) => {
      active ? geotags.selectGeotag(id) : geotags.unselectGeotag(id);
    };

    const groupActions = [
      genMenuItemDelete(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete these geotags completely?',
            onConfirm: () => {
              linkedObjectService.massiveDelete(
                selectedIds.value.map(item => ({ id: item }))
              );
              clearSelection();
            }
          }
        });
      })
    ];

    watch(
      () => list.value,
      value => {
        geotags.setGeotags(value);
      }
    );

    onMounted(() => {
      clearMarkers();
      geotags.setGeotags(list.value);
    });

    onBeforeUnmount(() => {
      geotags.clearGeotags();
    });

    return {
      getItemRoute,
      items: sortedItems,
      getItemActions,
      listActions,
      groupActions,
      filters,
      sorting: sortingMenu,
      currentSortBy,
      handleSort,
      handleSelectedItemIds,
      list,
      handleActive,
      refList,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen
    };
  }
};
</script>

<style></style>
