<template>
  <list-card
    ref="refList"
    :items="items"
    :list-actions="listActions"
    :group-actions="groupActions"
    :is-filter-active="isFilterActive"
    :sorting="sorting"
    :current-sort-by="currentSortBy"
    title="Plans"
    entity-type="plan"
    @click:sort="handleSort"
    @selectedItemIds="handleSelectedItemIds"
    @click:filter-icon="handleFilterOpen"
  >
    <template #item="{select, ...props}">
      <plan-list-item
        v-bind="props"
        :actions="getItemActions(props.item)"
        :to="getItemRoute(props.item)"
        @select="select"
      />
    </template>
  </list-card>
</template>

<script>
import ListCard from '@/components/list-card';
import PlanListItem from './PlanListItem';
import { useObjectListActions } from './listActions';

import { computed, ref } from '@vue/composition-api';

import { useSortBy } from '@/compositions/sortBy';
import { usePlanMenu } from '@/modules/plans/compositions/planMenu';
import { objectService } from '@/modules/objects/api';
import { usePlans } from '@/modules/plans/compositions/plans';
import sortingMenu from './sortingMenu';
import {
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemEnable
} from '@/utils/list-generators';
import { usePopup } from '@/compositions/popup';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { useFilter } from '@/compositions/filter';
import {
  PLAN_LIST_FILTER,
  usePlanFilters
} from '@/modules/plans/compositions/plan-filters';

export default {
  name: 'Plans',
  components: {
    ListCard,
    PlanListItem
  },

  setup(props, { root }) {
    const refList = ref(null);
    const { list } = usePlans();
    const popup = usePopup();

    const clearSelection = () => {
      refList.value && refList.value.clearSelection();
    };

    const getItemRoute = item => ({
      name: 'plan_card',
      params: {
        planId: item.id
      },
      query: root.$route.query
    });

    const currentSortBy = ref(sortingMenu[0].id);

    const selectedIds = ref([]);

    const getItemActions = entity => {
      return usePlanMenu({ entity });
    };

    const keyword = computed(() => {
      return root.$route?.query?.keyword;
    });

    const searchedList = computed(() => {
      return keyword.value
        ? list.value.filter(i => i.title.includes(keyword.value))
        : list.value;
    });

    const filters = usePlanFilters();

    const {
      filteredItems,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen
    } = useFilter(searchedList, filters, PLAN_LIST_FILTER);

    const { sortType, sortKey, sortedItems } = useSortBy(
      filteredItems,
      sortingMenu[0].sortKey,
      sortingMenu[0].sortType
    );

    const handleSort = e => {
      currentSortBy.value = e.id;
      sortType.value = e.sortType;
      sortKey.value = e.sortKey;
    };

    const toggleFavourite = ({ id, favourite }) => {
      objectService.setFavourite(id, !favourite);
    };

    const { listActions } = useObjectListActions();

    const handleSelectedItemIds = ids => {
      selectedIds.value = ids;
      console.info('not implemented yet');
    };

    const groupActions = [
      genMenuItemDisable(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Disable these plans?',
            onConfirm: () => {
              objectService.massiveUpdate(
                selectedIds.value.map(item => ({ id: item, enabled: false }))
              );
              clearSelection();
            }
          }
        });
      }),
      genMenuItemEnable(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Enable these plans?',
            onConfirm: () => {
              objectService.massiveUpdate(
                selectedIds.value.map(item => ({ id: item, enabled: true }))
              );
              clearSelection();
            }
          }
        });
      }),
      genMenuItemDelete(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete these plans completely?',
            onConfirm: () => {
              linkedObjectService
                .massiveDelete(selectedIds.value.map(item => ({ id: item })))
                .then(() => {});
              clearSelection();
            }
          }
        });
      })
    ];

    return {
      getItemRoute,
      items: sortedItems,
      getItemActions,
      listActions,
      groupActions,
      sorting: sortingMenu,
      currentSortBy,
      handleSort,
      toggleFavourite,
      handleSelectedItemIds,
      refList,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen
    };
  }
};
</script>

<style></style>
