<template>
  <list-card
    :items="items"
    :list-actions="[]"
    :group-actions="[]"
    :sorting="sorting"
    :current-sort-by="currentSortBy"
    :is-filter-active="isFilterActive"
    title="Notifications"
    :item-height="88"
    :items-count="totalCount"
    :filter-function="filterFunction"
    infinite
    @click:sort="handleSort"
    @active="handleActive"
    @selectedItemIds="handleSelectedItemIds"
    @infinite="handleInfinite"
    @click:filter-icon="handleFilterOpen"
  >
    <template #item="{select, ...props}">
      <notification-list-item
        v-bind="props"
        @select="select"
        @click="onClick(props.item)"
        @click:info="onClickNotificationInfo(props.item)"
      />
    </template>
    <template #toolbar-selection:extra-items>
      <v-btn icon color="primary" @click="downloadNotifications">
        <v-icon v-text="'$download'" />
      </v-btn>
    </template>
  </list-card>
</template>

<script>
import ListCard from '@/components/list-card';
import NotificationListItem from './NotificationListItem';

import { onMounted, ref, watch } from '@vue/composition-api';

import { useNotifications } from '@/modules/notifications/compositions/notifications';

import sorting from '@/modules/notifications/ui/notifications-list/sortingMenu';
import { usePopup } from '@/compositions/popup';
import { useObjects } from '@/modules/objects/compositions/objects';
import { useMap } from '@/compositions/map';
import { useFilter } from '@/compositions/filter';
import {
  NOTIFICATION_LIST_FILTER,
  useNotificationFilters
} from '@/modules/notifications/compositions/notification-filters';
import { useGeotags } from '@/modules/geotags/compositions/geotags';

export default {
  name: 'Notifications',
  components: {
    ListCard,
    NotificationListItem
  },
  props: {
    groupId: {
      type: [String, undefined],
      default: undefined
    }
  },
  setup(props, { root }) {
    const {
      items: list,
      load,
      loadMore,
      notificationFilter,
      notificationSort,
      totalCount
    } = useNotifications();
    const popup = usePopup();
    const {
      markers: { setMarkers }
    } = useMap();
    const { list: objectList } = useObjects();

    const { setGeotag, ALL_GEOTAG_ID } = useGeotags();

    onMounted(() => {
      setGeotag(ALL_GEOTAG_ID);
      setMarkers(objectList.value);
      load();
    });

    const { filters, applyFilter } = useNotificationFilters();

    const { currentFilter, isFilterActive, handleFilterOpen } = useFilter(
      list,
      filters,
      NOTIFICATION_LIST_FILTER
    );

    const getItemRoute = objectId => ({
      name: 'object_card',
      params: { objectId }
    });

    const onClick = item => {
      if (item.objectId) {
        root.$router.push(getItemRoute(item.objectId));
      } else {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            message:
              'This object has been deleted, the transition to it is impossible. You can view the notification info.',
            showCancelAction: false,
            confirmText: 'Close'
          }
        });
      }
    };

    const currentSortBy = ref('');

    const handleSort = sortItem => {
      notificationSort.value = sortItem.query;
      currentSortBy.value = sortItem.id;
    };

    handleSort(sorting[0]);

    const handleActive = ({ id, active }) => {
      // console.log('handle active', id, active);
      // todo handle active notifications
    };

    const handleSelectedItemIds = ids => {};

    const downloadNotifications = () => {};

    watch(
      () => currentFilter.value,
      () => {
        notificationFilter.value = {
          notification: applyFilter(currentFilter.value)
        };
      },
      {
        immediate: true
      }
    );

    const onClickNotificationInfo = item => {
      popup.open({
        component: () =>
          import(
            '@/modules/notifications/ui/notifications-list/NotificationInfo'
          ),
        props: {
          item
        }
      });
    };

    const handleInfinite = () => {
      loadMore();
    };

    const filterFunction = (item, query) =>
      item.message?.toLowerCase().includes(query?.toLowerCase()) ||
      item.id?.toLowerCase().includes(query?.toLowerCase());

    return {
      getItemRoute,
      items: list,
      sorting,
      currentSortBy,
      handleSort,
      handleActive,
      handleSelectedItemIds,
      downloadNotifications,
      onClickNotificationInfo,
      applyFilter,
      handleInfinite,
      onClick,
      filterFunction,
      isFilterActive,
      handleFilterOpen,
      currentFilter,
      notificationFilter,
      totalCount
    };
  }
};
</script>

<style></style>
