<template>
  <simple-card title="Change form" @click:back="handleBack">
    <base-list :items="items" />
    <v-list>
      <v-list-item>
        <v-btn outlined block @click="handleSave">Save</v-btn>
      </v-list-item>
    </v-list>
  </simple-card>
</template>

<script>
import SimpleCard from '@/components/simple-card';
import { useGeotag } from '../../compositions/geotag';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  watch
} from 'vue-demi';
import { genDefaultItem } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';
import { usePopup } from '@/compositions/popup';
import { onBeforeRouteLeave } from '@/compositions/router';
import { throttle } from 'throttle-debounce';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { objectPropertyService } from '@/modules/common/api';

import { useDefaultItemMenu } from './defaultItemMenu';
import { typeCast } from '@/provider/utils';

export default {
  name: 'GeotagChangeForm',
  components: {
    SimpleCard
  },
  props: {
    geotagId: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const { geotagId } = toRefs(props);
    const state = ref({
      name: '',
      minAltitude: '',
      maxAltitude: '',
      points: []
    });
    const edited = ref(false);
    const { item: entity, load } = useGeotag(geotagId.value);
    const { list } = useGeotags();
    const {
      geotags: {
        setMapToGeotagCenter,
        selectedGeotagIds,
        enableEditGeotag,
        disableEditGeotag,
        editGeotagsState,
        setGeotags
      },
      markers: { clearMarkers }
    } = useMap();
    const popup = usePopup();

    const { genMenu: genDefaultItemMenu } = useDefaultItemMenu();

    onBeforeRouteLeave((to, from, next) => {
      if (edited.value) {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Save new geometry of the geotag?',
            onCancel: () => {
              setGeotags(list.value);
              next();
            },
            onConfirm: async () => {
              await handleSave();
              next();
            }
          }
        });
      } else {
        next();
      }
    });

    const handleBack = () => {
      root.$router.push({
        name: 'geotag_card',
        params: root.$route.params
      });
    };

    const handleSave = async () => {
      await objectPropertyService.updateValues([
        {
          id: entity.value.positionPoints.id,
          value: state.value.points
        },
        {
          id: entity.value.positionMinAltitude.id,
          value: typeCast(
            entity.value.positionMinAltitude.type,
            state.value.minAltitude
          )
        },
        {
          id: entity.value.positionMaxAltitude.id,
          value: typeCast(
            entity.value.positionMaxAltitude.type,
            state.value.maxAltitude
          )
        }
      ]);
      setMapToGeotagCenter(geotagId.value);
    };

    const handleUpdateProperty = (stateKey, value) => {
      state.value[stateKey] = value;
      edited.value = true;
    };

    const items = computed(() => [
      genDefaultItem({
        subTitle: `Geotag: ${state.value.name || 'n/a'}`,
        icon: '$geotag',
        invert: true
      }),
      genDefaultItem({
        subTitle: `Ceiling height: ${state.value.maxAltitude}`,
        icon: '$max_altitude',
        invert: true,
        actions: genDefaultItemMenu(
          entity.value?.positionMaxAltitude,
          value => {
            handleUpdateProperty('maxAltitude', value);
          }
        )
      }),
      genDefaultItem({
        subTitle: `Floor height: ${state.value.minAltitude}`,
        icon: '$min_altitude',
        invert: true,
        actions: genDefaultItemMenu(
          entity.value?.positionMinAltitude,
          value => {
            handleUpdateProperty('minAltitude', value);
          }
        )
      })
    ]);

    onMounted(() => {
      load();
    });

    onBeforeUnmount(() => {
      disableEditGeotag(geotagId.value);
    });

    const throttleUpdate = throttle(500, value => {
      state.value = {
        ...state.value,
        ...value
      };
    });

    watch(
      () => entity.value,
      value => {
        selectedGeotagIds.value = [geotagId.value];
        clearMarkers();
        setGeotags([entity.value]);
        setMapToGeotagCenter(geotagId.value);
        enableEditGeotag(geotagId.value);
        console.log(entity.value);
        state.value = {
          name: value.name,
          minAltitude: value.positionMinAltitude?.value,
          maxAltitude: value.positionMaxAltitude?.value,
          points: value.positionPoints?.value
        };
        edited.value = false;
      }
    );

    watch(
      () => editGeotagsState.value,
      value => {
        throttleUpdate(value[geotagId.value]);
        edited.value = true;
      }
    );

    return {
      handleBack,
      items,
      handleSave,
      entity,
      editGeotagsState,
      state
    };
  }
};
</script>

<style></style>
